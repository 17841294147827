import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../utils/SupabaseClient';
import SessionForm from '../components/SessionForm';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { SessionTypesProvider } from '../contexts/sessionTypesContext';

function EditSessionPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [session, setSession] = useState(null);
  const [trackName, setTrackName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];

    const fetchSession = async () => {
      if (!id || id === 'undefined') {
        setError('No session ID provided');
        setLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('track_sessions')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;

        if (!data) {
          throw new Error('Session not found');
        }

        setSession(data);

        // Fetch track name
        const { data: trackData, error: trackError } = await supabase
          .from('tracks')
          .select('name')
          .eq('id', data.track_id)
          .single();

        if (trackError) throw trackError;
        console.log(trackData)
        setTrackName(trackData.name.replace(/ /g, '_'));
      } catch (error) {
        console.error('Error fetching session or track:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSession();
  }, [location.pathname]);

  const handleSave = async (updatedSession) => {
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];

    if (!id) {
      console.error('No session ID available for update');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('track_sessions')
        .update(updatedSession)
        .eq('id', id)
        .single();

      if (error) throw error;

      navigate(`/tracks/${data.track_id}`);
    } catch (error) {
      console.error('Error updating session:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleDelete = async () => {
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];

    if (!id) {
      console.error('No session ID available for deletion');
      return;
    }

    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        const { error } = await supabase
          .from('track_sessions')
          .delete()
          .eq('id', id);

        if (error) throw error;

        navigate(`/tracks/${trackName}`);
      } catch (error) {
        console.error('Error deleting session:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!session) {
    return <Typography>Session not found</Typography>;
  }

  return (
    <Box>
    <SessionTypesProvider trackId={session.track_id}>
      <SessionForm
          trackId={session.track_id}
          session={session}
          onSubmit={handleSave}
          handleClose={() => {
            navigate(`/tracks/${trackName}`)}}
        />
    </SessionTypesProvider>
      <Button 
        onClick={handleDelete} 
        variant="outlined" 
        color="error" 
        sx={{ mt: 2 }}
      >
        Delete Session
      </Button>
    </Box>
  );
}

export default EditSessionPage;