import { Checkbox, Stack, Typography, ButtonGroup, Button, FormControlLabel } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

const template = `Warm up
- hello world, 2x2x24kg
-- 2x30kg
-- 1x20kg
- hello world 2, 2x2x24kg
- WOW, 3
- wooooah, 3x6

Exercises
- hello world 2, 10x24kg
- WOW, 300m
- wooooah, 3x6min
`

const PreToJSON = () => {
    const theme = useTheme();
    const [text, setText] = useState(template);
    const [parsedText, setParsedText] = useState(jsonify(text));
    const [shouldShow, setShouldShow] = useState('both');

    useEffect(() => {
        setParsedText(jsonify(text));
    }, [text]);

    const handleCheckboxChange = (event) => {
        const index = event.target.closest('[data-index]').dataset.index.split('-');
        console.log('Checkbox state:', event.target.checked);  // Add this line
        setParsedText(prevParsedText => {
            let updatedJson = [...prevParsedText];
            if(index.length === 1){
                // all isDone inside sets should become true/false based on the checkbox state
                updatedJson[index[0]].sets_factual.forEach(set => set.isDone = event.target.checked);
            }
            else{
                updatedJson[index[0]].sets_factual[index[1]].isDone = event.target.checked;
            }
            setText(reverseJsonify(updatedJson))
            return updatedJson;
        });
    };

  return (
    <Stack direction="column" spacing={4}>
        <ButtonGroup
            value={shouldShow}
            variant='outlined'
        >
            <Button value="edit" variant={shouldShow === "edit" ? "contained" : "outlined"} onClick={(e) => setShouldShow(e.target.value)}>Edit</Button>
            <Button value="track" variant={shouldShow === "track" ? "contained" : "outlined"} onClick={(e) => setShouldShow(e.target.value)}>Track</Button>
            <Button value="both" variant={shouldShow === "both" ? "contained" : "outlined"} onClick={(e) => setShouldShow(e.target.value)}>Both</Button>
        </ButtonGroup>
        <Stack direction="row" spacing={4}>
            {
                shouldShow !== "track" 
                ? <>
                    <textarea value={text} onChange={(e) => setText(e.target.value)} style={{color: theme.palette.text.primary, backgroundColor: theme.palette.background.default}}/>
                    <pre> {JSON.stringify(parsedText, null, 2)}</pre>
                </> : <></>
            }
            {
                shouldShow !== "edit" 
                ? <div>
                    {
                        parsedText.map((el, index) => {
                            if(typeof el === 'string'){
                                return <Typography key={index} fontWeight="bold" sx={{fontSize: 20, mt: 3, mb: 1}}>
                                    {el}
                                </Typography>
                            }
                            else if(typeof el === 'object'){

                                // if all done — hide sub sets
                                let allDone = el.sets_factual.every(set => set.isDone);
                                let allSame = el.sets_factual.every(set => set.reps === el.sets_factual[0].reps && set.modifier === el.sets_factual[0].modifier);
                                if(allDone && allSame){
                                    return <Stack key={index} direction="row" alignItems="center" data-index={index} sx={{mb: 0}}>
                                                <FormControlLabel
                                                    control={<Checkbox 
                                                    checked={true} 
                                                    size="small" 
                                                    onChange={handleCheckboxChange}/>}
                                                    label={el.name + ", " + el.sets_factual.length + (el.sets_factual[0].reps > 1 ? "x" + el.sets_factual[0].reps : "") + (el.sets_factual[0].modifier ? "x" + el.sets_factual[0].modifier : "")}
                                                />
                                                {/* <Stack key={index} direction="row" alignItems="center" data-index={index} sx={{mb: 0}}>
                                                <Checkbox 
                                                checked={true} 
                                                size="small" 
                                                onChange={handleCheckboxChange}
                                                />
                                                <Typography>
                                                    {el.name + ", " + el.sets_factual.length + (el.sets_factual[0].reps > 1 ? "x" + el.sets_factual[0].reps : "") + (el.sets_factual[0].modifier ? "x" + el.sets_factual[0].modifier : "")}
                                                </Typography>
                                                </Stack> */}
                                    </Stack>
                                }
                                // if sets > 1 — show them in exercise description; if not — hide sets number
                                if(el.sets_factual.length === 1){
                                    return <Stack key={index} data-index={index} sx={{mb: 0}}>
                                        {/* <Stack direction="row" alignItems="center" sx={{mb: 0}}> */}
                                            {/* <Checkbox checked={el.sets_factual[0].isDone} size="small" onChange={handleCheckboxChange}/>
                                            <Typography>
                                                {el.name + ", " + el.sets_factual.length + (el.sets_factual[0].reps > 1 ? "x" + el.sets_factual[0].reps : "") + (el.sets_factual[0].modifier ? "x" + el.sets_factual[0].modifier : "")}
                                            </Typography> */}
                                            <FormControlLabel
                                                    control={<Checkbox 
                                                    checked={el.sets_factual[0].isDone} 
                                                    size="small" 
                                                    onChange={handleCheckboxChange}/>}
                                                    label={el.name + ", " + el.sets_factual.length + (el.sets_factual[0].reps > 1 ? "x" + el.sets_factual[0].reps : "") + (el.sets_factual[0].modifier ? "x" + el.sets_factual[0].modifier : "")}
                                                />
                                        {/* </Stack> */}
                                    </Stack>
                                }
                                else{
                                    return <Stack key={index} data-index={index} sx={{mb: 0}}>
                                            <FormControlLabel
                                                control={<Checkbox 
                                                checked={el.sets_factual.every(set => set.isDone)} 
                                                size="small" 
                                                onChange={handleCheckboxChange}/>}
                                                label={allSame ? 
                                                    el.name + ", " + el.sets_factual.length + (el.sets_factual[0].reps > 1 ? "x" + el.sets_factual[0].reps : "") + (el.sets_factual[0].modifier ? "x" + el.sets_factual[0].modifier : "") 
                                                    : el.name}
                                            />
                                        <Stack direction="column" sx={{mb: 0, ml: 4}}>
                                            {
                                                el.sets_factual.map((set, index2) => (
                                                    <Stack direction="row" key={index2} data-index={index+'-'+index2} alignItems="center">
                                                        <FormControlLabel
                                                            control={<Checkbox 
                                                            checked={set.isDone} 
                                                            size="small" 
                                                            onChange={handleCheckboxChange}/>}
                                                            label={(set.reps > 1 ? set.reps : "")+(set.reps > 1 && set.modifier ? 'x' : "")+(set.modifier ? set.modifier : "")}
                                                        />
                                                    </Stack>
                                                ))
                                        }
                                        </Stack>
                                    </Stack>
                                }
                            }
                            // default return
                            return null;
                        })
                    }
                </div>
                : <></>
            }
        </Stack>
    </Stack>
  );
};

function jsonify(text) {
    let r = text.split('\n').map((line, index) => {
        // if line is a subset of an exercise (starts with '--') — ignore it (as we will process it in the next IF)
        if(line.startsWith('--')){
            return '';
        }
        // If line is an exercise (starts with '- ') — check next lines
        if (line.startsWith('- ')) {
            let sets_arr = [];
            // If next lines are exercise subsets (starting with '--') — put them into sets array
            // and return exercise (just it's name) + its subsets
            if (text.split('\n')[index + 1] &&  text.split('\n')[index + 1].startsWith('--')) {
                // loop through the next lines until the line doesn't start with "--"
                let i = index + 1;
                // const currLine = text.split('\n')[i];

                while (text.split('\n')[i] &&  text.split('\n')[i].startsWith('--')) {
                    let [reps, modifier] = text.split('\n')[i].slice(2).trim().split('x');
                    let isDone = text.split('\n')[i].includes('+');
                    sets_arr.push({
                        reps: parseInt(reps) || 1,
                        modifier: modifier ? cleanStr(modifier.replace('+', '').trim()) : null,
                        // TODO: make it so it's obvious for the user that everything other than exercise name is ignored 
                        // for example, strikethrough text that is not exercise name and show a tooltip explaining it on hover (for both 'edit' and 'track' modes)
                        isDone: isDone
                    });
                    i++;
                }
                const sets_planned = parseVolumeStr(line.split(',')[1].trim());
                return {
                    name: line.slice(1).split(',')[0].trim(),
                    planEqualFact: false,
                    sets_planned,
                    sets_factual: sets_arr,
                    // sets: sets_arr,
                }
            }

            // parse the line to get exercise name, volume and isDone marks
            let [name, volume, isDone] = line.slice(1).trim().split(',').map(s => s.replace(',', '').trim());
            let {sets, reps, modifier} = parseVolumeStr(volume);
            // count "+" in isDone
            let doneCount = isDone ? isDone.split('').filter(s => s === '+' || s === '✅').length : 0;
            for (let i = 0; i < sets; i++) {
                sets_arr.push({
                    reps,
                    modifier: cleanStr(modifier),
                    'isDone': i < doneCount
                });
            }
            return {
                name,
                planEqualFact: true,
                sets_planned: {
                    sets,
                    reps,
                    modifier: cleanStr(modifier)
                },
                sets_factual: sets_arr,
                // sets: sets_arr,
            }
        }
        return line.trim();
    }).filter(line => line !== '');
    return r;
}

function parseVolumeStr(volumeStr) {
    const volume = volumeStr ? volumeStr.split('x') : [];
    let sets, reps, modifier;
    switch (volume.length) {
        // if volume is just 1 value, than we assume that it is volume modifier (like distance or weight)
        // and the exercise itself has just 1 set and 1 rep
        case 1:
            if (/[a-zA-Z]/.test(volume[0])) {
                sets = 1;
                reps = 1;
                modifier = volume[0] === '' ? null : volume[0];
            } else {
                sets = 1;
                reps = parseInt(volume[0]);
                modifier = null;
            }
            break;
        // if volume is 2 values...
        case 2:
            // ... and if second value has letters, than we assume that these 2 values are sets and volume modifier
            // and each set has just 1 rep
            if (/[a-zA-Z]/.test(volume[1])) {
                sets = volume[0] === '' ? 1 : parseInt(volume[0]);
                reps = 1;
                modifier = volume[1] === '' ? null : volume[1];
            // ... and if second value has not letters, than we assume that these 2 values are sets and reps
            } else {
                sets = volume[0] === '' ? 1 : parseInt(volume[0]);
                reps = volume[1] === '' ? 1 : parseInt(volume[1]);
                modifier = null;
            }
            break;
        // if volume has 3 values we assume that they are sets, reps and volume modifier (in this order)
        case 3:
            sets = volume[0] === '' ? 1 : parseInt(volume[0]);
            reps = volume[1] === '' ? 1 : parseInt(volume[1]);
            modifier = volume[2] === '' ? null : volume[2];
            break;
        // if there is no volume, we assume that exercise has 1 rep and 1 set
        default:
            sets = 1;
            reps = 1;
            modifier = null;
            break;
    }

    return {
        sets,
        reps,
        modifier
    }
}


// TODO: implement reverseJsonify so when the checkbox changes it's value it updates (1) parsedText and (2) text accordingly
// (1) should change the isDone value of a relevant set
// (2) should add or remove "+" either for the exercise isDone sections (after second ',' in the name of exercise) or for relevant subset
function reverseJsonify(jsonData) {
    const processedArrOfStr = []
    jsonData.forEach(item => {
        if(typeof item === 'string'){
            processedArrOfStr.push('');
            processedArrOfStr.push(item);
        }
        else{
            const planned_volume = `${item.sets_planned.sets > 1 ? item.sets_planned.sets : ''}${item.sets_planned.reps > 1 ? (item.sets_planned.sets > 1 ? 'x' : '') + item.sets_planned.reps : ''}${item.sets_planned.modifier ? ((item.sets_planned.sets > 1 || item.sets_planned.reps > 1) ? 'x' : '') + item.sets_planned.modifier : ''}`
            if(item.planEqualFact){
                const countSetIsDone = item.sets_factual.filter(set => set.isDone).length;
                let isDoneStr = ''
                for (let i = 0; i < countSetIsDone; i++) {
                    isDoneStr = isDoneStr+'+'
                }
                processedArrOfStr.push(`- ${item.name}${planned_volume !== '' ? ', ' + planned_volume : ''}${isDoneStr !== '' ? ', ' + isDoneStr : ''}`)
            } else {
                processedArrOfStr.push(`- ${item.name}${planned_volume !== '' ? ', ' + planned_volume : ''}`)
                for (let i = 0; i < item.sets_factual.length; i++) {
                    processedArrOfStr.push(`-- ${item.sets_factual[i].reps}${item.sets_factual[i].modifier !== null ?  'x' + item.sets_factual[i].modifier : ''}${item.sets_factual[i].isDone ? '+':''}`)
                }
            }    
        }
    })
    return processedArrOfStr.join('\n').trim()
}

function cleanStr(input) {
    // Remove '-' and '+'
    if(input) {
        let cleanedString = input.replace(/[-+]/g, '');
        return cleanedString;
    } else {
        return input
    }
  }


export default PreToJSON;