const data = {
  workouts: [
    {
      id: 1,
      dateTime: '2024-07-01T10:00:00',
      type: 'running',
      performance: 5,
      tiredness: 1,
      time: 60,
      calories: 600,
      exercises: [
        {
          name: 'interval running',
          details: {
            distance: '5000m'
          }
        }
      ]
    },
    {
      id: 2,
      dateTime: '2024-07-02T12:00:00',
      type: 'bodyweight',
      performance: 3,
      tiredness: 2,
      time: 45,
      calories: 300,
      exercises: [
        {
          name: 'pull-ups',
          sets: [
            { reps: 5, weight: '20kg' },
            { reps: 3, weight: '25kg' },
            { reps: 10, weight: 'bodyweight' }
          ]
        }
      ]
    },
    {
      id: 3,
      dateTime: '2024-07-03T14:00:00',
      type: 'swimming',
      performance: 4,
      tiredness: 3,
      time: 30,
      calories: 350,
      exercises: [
        {
          name: 'freestyle swimming',
          details: {
            distance: '1000m'
          }
        }
      ]
    },
    {
      id: 4,
      dateTime: '2024-07-03T18:00:00',
      type: 'swimming',
      performance: 1,
      tiredness: 1,
      time: 80,
      calories: 990,
      exercises: [
        {
          name: 'freestyle swimming',
          details: {
            distance: '1000m'
          }
        }
      ]
    },
    // other workouts
  ],
  workoutTypes: ['running', 'bodyweight', 'swimming'],
  performanceCategories: [
    { value: 1, label: 'Poor' },
    { value: 2, label: 'Fair' },
    { value: 3, label: 'Good' },
    { value: 4, label: 'Very Good' },
    { value: 5, label: 'Excellent' }
  ],
  tirednessCategories: [
    { value: 1, label: 'Not Tired' },
    { value: 2, label: 'Slightly Tired' },
    { value: 3, label: 'Tired' },
    { value: 4, label: 'Very Tired' },
    { value: 5, label: 'Exhausted' }
  ],
  exerciseOptions: {
    running: ['interval running', 'sprint', 'long distance'],
    bodyweight: ['push-ups', 'pull-ups', 'squats'],
    swimming: ['freestyle swimming', 'butterfly stroke', 'backstroke']
  },
  attributeOptions: {
    'interval running': ['distance', 'time'],
    'sprint': ['distance', 'time'],
    'long distance': ['distance', 'time'],
    'push-ups': ['reps', 'sets'],
    'pull-ups': ['reps', 'sets'],
    'squats': ['reps', 'sets'],
    'freestyle swimming': ['distance', 'time'],
    'butterfly stroke': ['distance', 'time'],
    'backstroke': ['distance', 'time']
  }
};

export default data;