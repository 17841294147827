import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { TextField, Button, Stack, Autocomplete, CircularProgress } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import CustomSelect from './CustomSelect';
import { supabase } from '../utils/SupabaseClient';
import SessionTypesContext from '../contexts/sessionTypesContext';

const getCurrentDateTime = () => {
    return new Date();
};

function SessionForm({
    trackId,
    session,
    handleClose,
    onSessionChange,
    performanceOptions = ['awful', 'weak', 'medium', 'high', 'outstanding'],
    funOptions = ['boring', 'dull', 'average', 'fun', 'exciting'],
}) {
    const { sessionTypes, sessionTypesRef, isLoading, handleCreateSessionType, handleEditSessionType, handleDeleteSessionType } = useContext(SessionTypesContext);
    
    const [formData, setFormData] = useState({
        date: session?.date ?
            (typeof session.date === 'string' ? parseISO(session.date) : session.date)
            : getCurrentDateTime(),
        duration: session?.duration || '',
        focus: session?.focus || '',
        performance: session?.performance || null,
        fun: session?.fun || null,
        retro: session?.retro || '',
        type: session?.type || '',
        track_id: session?.track_id || trackId,
        session_type_id: session?.session_type_id || null,
        session_id: session?.id || null,
    });

    // Ref to store previous session ID
    const prevSessionIdRef = useRef();

    // Effect to handle form data changes and propagate to parent
    useEffect(() => {
        if (onSessionChange) {
            onSessionChange({
                date: formData.date,
                duration: formData.duration,
                focus: formData.focus,
                performance: formData.performance,
                fun: formData.fun,
                retro: formData.retro,
                type: formData.type,
                session_type_id: formData.session_type_id,
                session_id: formData.session_id,
            });
        }
    }, [formData, onSessionChange]);

    // Effect to initialize formData when session prop changes
    useEffect(() => {
        if (session) {
            // Only update if session ID has changed
            if (prevSessionIdRef.current !== session.id) {
                setFormData({
                    date: session.date ?
                        (typeof session.date === 'string' ? parseISO(session.date) : session.date)
                        : getCurrentDateTime(),
                    duration: session.duration || '',
                    focus: session.focus ? session.focus.replace(/\\n/g, '\n') : '',  // Replace \\n with \n
                    performance: session.performance || null,
                    fun: session.fun || null,
                    retro: session.retro ? session.retro.replace(/\\n/g, '\n') : '',  // Replace \\n with \n
                    type: session.type || '',
                    track_id: session.track_id || trackId,
                    session_type_id: session.session_type_id || null,
                    session_id: session.id || null,
                });

                // Update the previous session ID
                prevSessionIdRef.current = session.id;
            }
        }
    }, [session, trackId]);

    // Effect to update the type field based on session_type_id (from supabase session or because if user changes) and sessionTypesRef
    useEffect(() => {
        console.log('FIRED')
        let selectedTypeName = '';
        if (formData.session_type_id && sessionTypesRef.length > 0) {
            const selectedType = sessionTypesRef.find(type => type.id === formData.session_type_id);
            selectedTypeName = selectedType ? selectedType.name : '';
            // selectedTypeID = selectedType ? selectedType.id : '';
        }
        setFormData(prev => ({
            ...prev,
            type: selectedTypeName
        }));
    }, [formData.session_type_id, sessionTypesRef]);

    console.log(formData)

    const handleDateChange = (newValue) => {
        setFormData(prev => ({
            ...prev,
            date: newValue
        }));
    };

    const handleAutocompleteChange = (name) => (event, newValue) => {
        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }));
    };

    const handleSessionTypeChange = useCallback((newValue) => {
        if (newValue === null) {
            setFormData(prev => ({
                ...prev,
                session_type_id: '',
                type: ''
            }));
        } else {
            const selectedType = sessionTypesRef.find(type => type.name === newValue);
            if (selectedType) {
                setFormData(prev => ({
                    ...prev,
                    session_type_id: selectedType.id,
                    type: selectedType.name
                }));
            }
        }
    }, [sessionTypesRef]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        document.activeElement.blur();
        const payload = {
            date: formData.date,
            duration: formData.duration,
            focus: formData.focus.replace(/\n/g, '\\n'),  // Ensure newlines are properly escaped
            performance: formData.performance,
            fun: formData.fun,
            retro: formData.retro.replace(/\n/g, '\\n'),  // Ensure newlines are properly escaped
            track_id: formData.track_id,
            session_type_id: formData.session_type_id === '' ? null : formData.session_type_id,
        };

        if (formData.session_id) {
            const { error } = await supabase
                .from('track_sessions')
                .update(payload)
                .eq('id', formData.session_id);
            if (error) {
                console.error('Error updating session:', error);
            } else {
                handleClose();
            }
        } else {
            const { error } = await supabase
                .from('track_sessions')
                .insert([payload]);
            if (error) {
                console.error('Error creating session:', error);
            } else {
                handleClose();
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Wrapper function for creating session type in SessionForm
    const handleCreateSessionTypeWrapper = async (createdOption) => {
        const createdType = await handleCreateSessionType(createdOption);
        if (createdType) {
            setFormData(prev => ({
                ...prev,
                type: createdType.name,
                session_type_id: createdType.id
            }));
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={handleSubmit}>
                <Stack gap={2} sx={{ marginTop: 1 }}>
                    <DateTimePicker
                        label="Date"
                        value={formData.date}
                        onChange={handleDateChange}
                        slotProps={{ textField: { variant: 'outlined' } }}
                        ampm={false}
                    />
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <CustomSelect
                            label="Session Type"
                            options={sessionTypes}
                            selectedOption={formData.type || null}
                            onChange={handleSessionTypeChange}
                            onCreateOption={handleCreateSessionTypeWrapper}
                            onEditOption={(oldType, newType) => {
                                handleEditSessionType(oldType, newType);
                                if(formData.type === oldType) {
                                    const updatedType = sessionTypesRef.find(t => t.name === newType);
                                    setFormData(prev => ({
                                        ...prev,
                                        type: newType,
                                        session_type_id: updatedType ? updatedType.id : prev.session_type_id
                                    }));
                                }
                            }}
                            onDeleteOption={handleDeleteSessionType}
                        />
                    )}
                    <TextField
                        label="Duration"
                        name="duration"
                        value={formData.duration}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Focus"
                        name="focus"
                        value={formData.focus}
                        onChange={handleChange}
                        multiline  // Enable multiline input
                    />
                    <Autocomplete
                        options={performanceOptions}
                        value={formData.performance}
                        onChange={handleAutocompleteChange('performance')}
                        renderInput={(params) => <TextField {...params} label="Performance" />}
                    />
                    <Autocomplete
                        options={funOptions}
                        value={formData.fun}
                        onChange={handleAutocompleteChange('fun')}
                        renderInput={(params) => <TextField {...params} label="Fun" />}
                    />
                    <TextField
                        label="Retro"
                        name="retro"
                        value={formData.retro}
                        onChange={handleChange}
                        multiline  // Enable multiline input
                        fullWidth
                    />
                    <Button type="submit" variant="contained" color="primary">
                        {formData.session_id ? 'Update Session' : 'Add Session'}
                    </Button>
                </Stack>
            </form>
        </LocalizationProvider>
    );
}

export default SessionForm;