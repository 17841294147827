import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Stack,
  Menu,
  MenuItem,
  ListSubheader,
  InputAdornment,
  IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomSelect from '../components/CustomSelect';
import data from '../data/workouts';
import { useTheme } from '@emotion/react';


const {
  workouts,
  workoutTypes,
  performanceCategories,
  tirednessCategories,
  exerciseOptions
} = data;

const WorkoutDetail = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const workout = workouts.find(w => w.id === parseInt(id));

  const [details, setDetails] = useState(() => {
    if (workout) {
      return {
        ...workout,
        exercises: workout.exercises.map(ex => ({
          ...ex,
          details: ex.details || []
        }))
      };
    }
    return {
      exercises: []
    };
  });

  const [volumeType, setVolumeType] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(null);

  if (!workout) {
    return <Typography variant="h5">Workout not found</Typography>;
  }

  const handleChange = (name, value) => {
    setDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleExerciseChange = (index, name, value) => {
    const newExercises = [...details.exercises];
    newExercises[index] = {
      ...newExercises[index],
      [name]: value,
    };
    setDetails({ ...details, exercises: newExercises });
  };

  const handleAttributeChange = (exerciseIndex, attributeIndex, name, value) => {
    const newExercises = [...details.exercises];
    newExercises[exerciseIndex].details[attributeIndex] = { ...newExercises[exerciseIndex].details[attributeIndex], [name]: value };
    setDetails({ ...details, exercises: newExercises });
  };

  const handleVolumeTypeChange = (exerciseIndex, type) => {
    setVolumeType(prev => ({ ...prev, [exerciseIndex]: type }));
    setAnchorEl(null);
  };

  const addExercise = () => {
    setDetails(prevDetails => ({
      ...prevDetails,
      exercises: [...prevDetails.exercises, { name: '', details: [] }]
    }));
  };

  const handleMenuOpen = (event, exerciseIndex) => {
    setAnchorEl(event.currentTarget);
    setCurrentExerciseIndex(exerciseIndex);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentExerciseIndex(null);
  };

  const deleteExercise = (indexToDelete) => {
    setDetails(prevDetails => ({
      ...prevDetails,
      exercises: prevDetails.exercises.filter((_, index) => index !== indexToDelete)
    }));
  };

  const renderVolumeFields = (exerciseIndex) => {
    const type = volumeType[exerciseIndex];
    if (type === 'Sets x Reps x Weights') {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <TextField
            label="Sets"
            type="number"
            onChange={(e) => handleAttributeChange(exerciseIndex, 0, 'sets', e.target.value)}
            sx={{ mr: 1 }}
          />
          <Typography sx={{ mr: 1 }}>x</Typography>
          <TextField
            label="Reps"
            type="number"
            onChange={(e) => handleAttributeChange(exerciseIndex, 1, 'reps', e.target.value)}
            sx={{ mr: 1 }}
          />
          <Typography sx={{ mr: 1 }}>x</Typography>
          <TextField
            label="Weights"
            type="number"
            InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
            onChange={(e) => handleAttributeChange(exerciseIndex, 2, 'weights', e.target.value)}
            sx={{ mr: 1 }}
          />
        </Box>
      );
    } else if (type === 'Distance (m)') {
      return (
        <TextField
          label="Distance (m)"
          type="number"
          onChange={(e) => handleAttributeChange(exerciseIndex, 0, 'distance', e.target.value)}
          sx={{ mt: 2 }}
        />
      );
    } else if (type === 'Time (min)') {
      return (
        <TextField
          label="Time (min)"
          type="number"
          onChange={(e) => handleAttributeChange(exerciseIndex, 0, 'time', e.target.value)}
          sx={{ mt: 2 }}
        />
      );
    }
    return null;
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button variant="outlined" onClick={() => navigate('/list')}>
        ←
      </Button>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Workout details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            label="Type"
            initialOptions={workoutTypes}
            defaultValue={details.type}
            onChange={(value) => handleChange('type', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            label="Performance"
            initialOptions={performanceCategories.map(category => category.label)}
            defaultValue={performanceCategories.find(category => category.value === details.performance)?.label}
            onChange={(value) => handleChange('performance', performanceCategories.find(category => category.label === value).value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            label="Tiredness"
            initialOptions={tirednessCategories.map(category => category.label)}
            defaultValue={tirednessCategories.find(category => category.value === details.tiredness)?.label}
            onChange={(value) => handleChange('tiredness', tirednessCategories.find(category => category.label === value).value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Time"
            value={details.time}
            onChange={(e) => handleChange('time', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Calories"
            value={details.calories}
            onChange={(e) => handleChange('calories', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            Exercises
          </Typography>
        </Grid>
        {details.exercises.map((exercise, exerciseIndex) => (
          <Grid item xs={12} key={exerciseIndex}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <CustomSelect
                label="Exercise"
                initialOptions={exerciseOptions[details.type] || []}
                defaultValue={exercise.name}
                onChange={(value) => handleExerciseChange(exerciseIndex, 'name', value)}
              />
              <IconButton onClick={(e) => handleMenuOpen(e, exerciseIndex)}>
                <MoreVertIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              {renderVolumeFields(exerciseIndex)}
            </Box>
            <Stack spacing={1} direction="row">
              {!volumeType[exerciseIndex] && (
                <Button size="small" variant="outlined" onClick={(e) => handleMenuOpen(e, exerciseIndex)}>
                  Set volume
                </Button>
              )}
              <Button size="small" variant="outlined" onClick={() => console.log('Add note')}>
                Add note
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => deleteExercise(exerciseIndex)}>
                Delete
              </Button>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" onClick={addExercise}>
            Add Exercise
          </Button>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ 
          '& .MuiPaper-root': { 
            backgroundColor: theme.palette.background.default 
          } 
        }}
      >
        <ListSubheader>Set volume</ListSubheader>
        <MenuItem onClick={() => handleVolumeTypeChange(currentExerciseIndex, 'Sets x Reps x Weights')}>Sets x Reps x Weights</MenuItem>
        <MenuItem onClick={() => handleVolumeTypeChange(currentExerciseIndex, 'Distance (m)')}>Distance (m)</MenuItem>
        <MenuItem onClick={() => handleVolumeTypeChange(currentExerciseIndex, 'Time (min)')}>Time (min)</MenuItem>
        {volumeType[currentExerciseIndex] && (
          <MenuItem onClick={() => handleVolumeTypeChange(currentExerciseIndex, '')}>None</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default WorkoutDetail;