import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Box, Typography, Slider, TextField, Dialog, DialogTitle, DialogActions, DialogContentText, Button, DialogContent, SwipeableDrawer, useMediaQuery, IconButton, Stack } from '@mui/material';
import ActivityIndex from '../components/ActivityIndex';
import FloatingButton from '../components/FloatingButton';
import SessionForm from '../components/SessionForm.js';
import StartSession from '../components/StartSession.js';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useParams } from 'react-router-dom';
import SessionTable from '../components/SessionTable.js';
import useThrottle from '../hooks/useThrottle'; // Custom hook for throttling
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { supabase } from '../utils/SupabaseClient';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash'; // Add this import
import { SessionTypesProvider } from '../contexts/sessionTypesContext';
import NotFound from './NotFound';

const TrackDetails = () => {
    const { trackId } = useParams();
    const navigate = useNavigate();
    const [trackSessions, setTrackSessions] = useState([]);
    const [trackIdFromDB, setTrackIdFromDB] = useState(null); // State to store track_id from DB
    const [targetProgress, setTargetProgress] = useState(150);
    const [openAddSession, setOpenAddSession] = useState(false);
    const [openStartSession, setOpenStartSession] = useState(false);
    const [description, setDescription] = useState('');
    const sessionTableRef = useRef(null);
    const [tableWidth, setTableWidth] = useState('auto');
    const [title, setTitle] = useState(trackId.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()));
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const throttledDescription = useThrottle(description, 500);
    const [openActivityDialog, setOpenActivityDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isUpdatingTitle, setIsUpdatingTitle] = useState(false);
    const [isUpdatingDescription, setIsUpdatingDescription] = useState(false); // New state for description updating
    const [trackExists, setTrackExists] = useState(true); // Add this state

    // Create refs for dependencies
    const trackIdFromDBRef = useRef(trackIdFromDB);
    const navigateRef = useRef(navigate);
    const isUpdatingTitleRef = useRef(isUpdatingTitle);
    const isUpdatingDescriptionRef = useRef(isUpdatingDescription); // Ref for description updating

    // Update refs when dependencies change
    useEffect(() => {
        trackIdFromDBRef.current = trackIdFromDB;
    }, [trackIdFromDB]);

    useEffect(() => {
        navigateRef.current = navigate;
    }, [navigate]);

    useEffect(() => {
        isUpdatingTitleRef.current = isUpdatingTitle;
    }, [isUpdatingTitle]);

    useEffect(() => {
        isUpdatingDescriptionRef.current = isUpdatingDescription;
    }, [isUpdatingDescription]); // Update ref when description updating state changes

    // Define the debounced function for updating the track name
    const updateTrackName = useMemo(
        () =>
            debounce(async (newTitle) => {
                if (trackIdFromDBRef.current && !isUpdatingTitleRef.current) {
                    setIsUpdatingTitle(true);
                    try {
                        const { error } = await supabase
                            .from('tracks')
                            .update({ name: newTitle })
                            .eq('id', trackIdFromDBRef.current);

                        if (error) throw error;

                        // Update URL
                        const newSlug = newTitle.toLowerCase().replace(/ /g, '_');
                        navigateRef.current(`/tracks/${newSlug}`, { replace: true });

                        // Update document title
                        document.title = `${newTitle} - FiTrack`;
                    } catch (error) {
                        console.error('Error updating track name:', error);
                        // Optionally, show an error message to the user
                    } finally {
                        setIsUpdatingTitle(false);
                    }
                }
            }, 500),
        [] // Empty dependency array ensures this is created only once
    );

    // Define the debounced function for updating the track description
    const updateTrackDescription = useMemo(
        () =>
            debounce(async (newDescription) => {
                if (trackIdFromDBRef.current && !isUpdatingDescriptionRef.current) {
                    setIsUpdatingDescription(true);
                    try {
                        const { error } = await supabase
                            .from('tracks')
                            .update({ description: newDescription })
                            .eq('id', trackIdFromDBRef.current);

                        if (error) throw error;

                        // Optionally, you can handle success feedback here
                    } catch (error) {
                        console.error('Error updating track description:', error);
                        // Optionally, show an error message to the user
                    } finally {
                        setIsUpdatingDescription(false);
                    }
                }
            }, 500),
        [] // Empty dependency array ensures this is created only once
    );

    // Cleanup the debounced functions on unmount
    useEffect(() => {
        return () => {
            updateTrackName.cancel();
            updateTrackDescription.cancel(); // Cancel description update on unmount
        };
    }, [updateTrackName, updateTrackDescription]);

    useEffect(() => {
        const fetchTrackSessions = async () => {
            if (!trackIdFromDB) {
                const { data: trackData, error: trackError } = await supabase
                    .from('tracks')
                    .select('id, description') // Fetch description as well
                    .eq('name', trackId.replace(/_/g, ' '))
                    .single();

                if (trackError || !trackData) {
                    console.error('Error fetching track id:', trackError);
                    setTrackExists(false); // Set trackExists to false if there's an error or no data
                    return;
                }
                setTrackIdFromDB(trackData?.id);
                setDescription(trackData?.description || ''); // Set description from DB

                // Fetch sessions immediately after setting trackIdFromDB
                if (trackData?.id) {
                    const { data, error } = await supabase
                        .from('track_sessions')
                        .select('*')
                        .eq('track_id', trackData.id);

                    if (error) {
                        console.error('Error fetching track sessions:', error);
                    } else {
                        setTrackSessions(data);
                    }
                }
            }
        };

        fetchTrackSessions();
    }, [trackId, trackIdFromDB]);

    useEffect(() => {
        if (trackIdFromDB) {
            const subscription = supabase
                .channel('public:track_sessions')
                .on('postgres_changes', { event: '*', schema: 'public', table: 'track_sessions', filter: `track_id=eq.${trackIdFromDB}` }, payload => {
                    setTrackSessions(prevSessions => {
                        const newSessions = [...prevSessions];
                        const index = newSessions.findIndex(session => session.id === payload.new.id);
                        if (index > -1) {
                            newSessions[index] = payload.new;
                        } else {
                            newSessions.push(payload.new);
                        }
                        return newSessions;
                    });
                })
                .subscribe();

            return () => {
                supabase.removeChannel(subscription);
            };
        }
    }, [trackIdFromDB]);

    const saveChanges = useCallback(() => {
        // Save title and description to the server or local storage
        console.log('Saving changes:', { title, description });
    }, [title, description]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                saveChanges();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [saveChanges]);

    useEffect(() => {
        saveChanges();
    }, [throttledDescription, saveChanges]);

    useEffect(() => {
        if (sessionTableRef.current) {
            setTableWidth(sessionTableRef.current.offsetWidth);
        }
    }, []);

    const sortedWorkouts = useMemo(() => {
        return trackSessions.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
    }, [trackSessions]);

    const handleSliderChange = (event, newValue) => {
        setTargetProgress(newValue);
    };

    const buttons = useMemo(() => [
        {
            open: openAddSession,
            setOpen: setOpenAddSession,
            title: "Add Session",
            children: <SessionTypesProvider trackId={trackIdFromDB}><SessionForm trackId={trackIdFromDB} handleClose={() => setOpenAddSession(false)} /></SessionTypesProvider>, // Ensure trackIdFromDB is passed
            icon: <AddIcon />,
            label: "Add Session",
            extended: true
        },
        {
            open: openStartSession,
            setOpen: setOpenStartSession,
            title: "Start Session",
            children: <SessionTypesProvider trackId={trackIdFromDB}><StartSession trackId={trackIdFromDB} handleClose={() => setOpenStartSession(false)}/></SessionTypesProvider>,
            icon: <PlayArrowIcon />,
            label: "Start Session",
            extended: true,
        },
    ], [openAddSession, openStartSession, trackIdFromDB]);

    const handleTitleClick = () => {
        setIsEditingTitle(true);
    };

    const handleDescriptionClick = () => {
        setIsEditingDescription(true);
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
    };

    const handleTitleBlur = () => {
        setIsEditingTitle(false);
        updateTrackName(title);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleDescriptionBlur = () => { // New handler for description blur
        setIsEditingDescription(false);
        updateTrackDescription(description);
    };

    const handleDeleteTrack = async () => {
        try {
            const { error } = await supabase
                .from('tracks')
                .delete()
                .eq('id', trackIdFromDB);

            if (error) throw error;

            navigate('/tracks');
        } catch (error) {
            console.error('Error deleting track:', error);
            // Optionally, show an error message to the user
        } finally {
            setOpenDeleteDialog(false);
        }
    };

    const handleDeleteSession = (sessionId) => {
        setTrackSessions((prevSessions) => prevSessions.filter(session => session.id !== sessionId));
    };

    if (!trackExists) {
        return <NotFound />;
    }

    return (
        <>
            <Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {isEditingTitle ? (
                        <TextField
                            value={title}
                            onChange={handleTitleChange}
                            onBlur={handleTitleBlur}
                            autoFocus
                            sx={{ mt: 4, mb: 2 }}
                        />
                    ) : (
                        <Stack direction={'row'} gap={1} alignItems={'center'} sx={{ mt: 2, mb: 1 }}>
                            <Typography variant="h6" onClick={handleTitleClick}>
                                {title}
                            </Typography>
                            <IconButton
                                onClick={() => setOpenDeleteDialog(true)}
                            >
                                <MoreVertIcon fontSize='small' />
                            </IconButton>
                        </Stack>
                    )}

                    {isEditingDescription ? (
                        <TextField
                            label="Track description"
                            multiline
                            fullWidth
                            value={description}
                            onChange={handleDescriptionChange}
                            onBlur={handleDescriptionBlur} // Use the new blur handler
                            autoFocus
                            sx={{ mb: 2, width: tableWidth }}
                        />
                    ) : (
                        <Typography
                            variant="body2"
                            textAlign='center'
                            sx={{ mb: 2, width: tableWidth, whiteSpace: 'pre-line' }}
                            color={description === '' ? 'secondary' : 'primary'}
                            onClick={handleDescriptionClick}
                        >
                            {description || 'Click to add a description'}
                        </Typography>
                    )}
                    <Box sx={{ position: 'relative', width: 'fit-content' }}>
                        <ActivityIndex
                            progress={targetProgress}
                            size={200} strokeWidth={50}
                            actionOnClick={() => setOpenActivityDialog(true)}
                        />
                    </Box>
                    <Slider
                        value={targetProgress}
                        onChange={handleSliderChange}
                        aria-labelledby="progress-slider"
                        min={0}
                        max={500}
                        step={0.5}
                        sx={{ width: tableWidth, marginTop: 2, marginBottom: 2 }}
                    />

                    <Box ref={sessionTableRef} sx={{ width: '100%' }}>
                        <SessionTable sessions={sortedWorkouts} trackId={trackId} onDelete={handleDeleteSession} />
                    </Box>
                </Box>
            </Box>
            <FloatingButton buttons={buttons} extendOnHover={true} />

            {isMobile ? (
                <SwipeableDrawer
                    anchor="bottom"
                    open={openActivityDialog}
                    onClose={() => setOpenActivityDialog(false)}
                    onOpen={() => setOpenActivityDialog(true)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            padding: 3,
                            paddingTop: 4,
                        },
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenActivityDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </SwipeableDrawer>
            ) : (
                <Dialog open={openActivityDialog} onClose={() => setOpenActivityDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>
                        Activity Details
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenActivityDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {/* Add your content here */}
                    </DialogContent>
                </Dialog>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Delete Track</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this track? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteTrack} color="error" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TrackDetails;