import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';

const Workouts = () => {
  return (
    <List>
      <ListItem button component={Link} to="/list">
        <ListItemText primary="List" />
      </ListItem>
      <ListItem button component={Link} to="/templates">
        <ListItemText primary="Templates" />
      </ListItem>
      <ListItem button component={Link} to="/insights_and_analytics">
        <ListItemText primary="Insights and Analytics" />
      </ListItem>
      <ListItem button component={Link} to="/tracks">
        <ListItemText primary="Tracks" />
      </ListItem>
    </List>
  );
};

export default Workouts;
