import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  TextField, MenuItem, Button, IconButton, Box,
  Dialog, DialogActions, DialogContent, DialogContentText,
  Chip, Typography
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const CustomSelect = ({
  options = [],
  label,
  selectedOption = null,
  onChange = () => {},
  onCreateOption = null,
  onEditOption = null,
  onDeleteOption = null,
}) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const wrapperRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [optionToEdit, setOptionToEdit] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Filter options based on inputValue
  const filteredOptions = options.filter(option =>
    option?.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Handle option selection
  const handleSelectOption = useCallback((option, keepDropdownVisible = false) => {
    onChange(option);
    setInputValue('');
    if (!keepDropdownVisible) {
      setDropdownVisible(false);
    }
  }, [onChange]);

  // Handle creating a new option
  const handleCreateOption = useCallback(() => {
    if (inputValue && !options.includes(inputValue)) {
      if (onCreateOption) {
      onCreateOption(inputValue);
      }
      onChange(inputValue); // Set the newly created option as selected
      setInputValue('');
      setDropdownVisible(false);
    }
  }, [onCreateOption, inputValue, onChange, options]);

  // Handle deleting an option
  const handleDeleteOption = (option) => {
    if (onDeleteOption) {
      onDeleteOption(option);
    }
    if (selectedOption === option) {
      onChange(null);
    }
    setOpenConfirmDelete(false);
  };

  // Handle clearing the current selection
  const handleClearSelection = useCallback(() => {
    onChange(null);
    setInputValue('');
    setDropdownVisible(false);
  }, [onChange]);

  // Handle editing an option
  const handleEditOption = () => {
    if (editValue.trim() === '') {
      // If the edit value is empty, prompt deletion
      if (onDeleteOption && optionToEdit) {
        onDeleteOption(optionToEdit);
        if (selectedOption === optionToEdit) {
          onChange(null);
        }
      }
    } else {
      if (onEditOption && optionToEdit) {
        onEditOption(optionToEdit, editValue.trim());
        if (selectedOption === optionToEdit) {
          onChange(editValue.trim());
        }
      }
    }
    setOpenEditDialog(false);
    setIsDialogOpen(false);
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (dropdownVisible) {
        const totalOptions = filteredOptions.length + (inputValue && !options.includes(inputValue) ? 1 : 0);

        if (event.key === 'ArrowDown') {
          setHighlightedIndex((prevIndex) => (prevIndex + 1) % totalOptions);
          event.preventDefault();
        } else if (event.key === 'ArrowUp') {
          setHighlightedIndex((prevIndex) => (prevIndex - 1 + totalOptions) % totalOptions);
          event.preventDefault();
        } else if (event.key === 'Enter') {
          if (highlightedIndex >= 0 && highlightedIndex < filteredOptions.length) {
            handleSelectOption(filteredOptions[highlightedIndex], true);
          } else if (highlightedIndex === filteredOptions.length && inputValue && !options.includes(inputValue)) {
            handleCreateOption(true);
          } else if (inputValue && highlightedIndex === -1) {
            handleCreateOption(true);
          }
          event.preventDefault();
        } else if (event.key === 'Tab' || event.key === 'Escape') {
          setDropdownVisible(false);
        } else if ((event.key === 'Backspace' || event.key === 'Delete') && inputValue === '' && selectedOption) {
          handleClearSelection();
        }
      }
    };

    const inputElement = inputRef.current;
    inputElement.addEventListener('keydown', handleKeyDown);
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [dropdownVisible, filteredOptions, highlightedIndex, inputValue, selectedOption, options, handleSelectOption, handleCreateOption, handleClearSelection]);

  // Handle clicking outside the component to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={wrapperRef}
      sx={{ position: 'relative' }}
      onFocus={() => {
        if (!isDialogOpen) {
          setDropdownVisible(true);
          setIsFocused(true);
        }
      }}
    >
      <TextField
        variant="outlined"
        label={label}
        fullWidth
        focused={!!isFocused}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          pointerEvents: 'none',
        }}
        InputLabelProps={{
          shrink: !!(isFocused || selectedOption || inputValue !== ''),
        }}
        InputProps={{
          readOnly: true,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: theme.spacing(1.8, 1, 1, 1),
          bottom: '4px',
          top: '0px'
        }}
        onClick={() => inputRef.current.focus()}
      >
        {selectedOption && (
          <Chip
            label={selectedOption}
            onDelete={handleClearSelection}
            sx={{
              marginRight: '4px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '& .MuiChip-deleteIcon': {
                color: theme.palette.secondary.main,
                '&:hover': {
                  color: theme.palette.primary.contrastText,
                },
              },
            }}
          />
        )}
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={() => {
            if (!dropdownVisible) {
              setDropdownVisible(true);
              setIsFocused(true);
            }
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          sx={{ flex: 1, marginLeft: selectedOption ? '8px' : '0' }}
          inputRef={inputRef}
        />
      </Box>
      {dropdownVisible && (filteredOptions.length > 0 || inputValue !== '') && (
        <Box
          ref={dropdownRef}
          sx={{
            position: 'absolute',
            top: 'calc(100% + 4px)',
            left: 0,
            right: 0,
            maxHeight: filteredOptions.length > 4 ? 170 : 'auto', // Set max height if more than 4 options
            overflowY: filteredOptions.length > 4 ? 'auto' : 'visible', // Enable scrolling if more than 4 options
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(0.5),
            backgroundColor: theme.palette.background.paper,
            zIndex: 1300,
            padding: theme.spacing(1, 0, 0.5, 0)
          }}
        >
          {filteredOptions.map((option, index) => (
            <MenuItem
              key={option}
              onClick={() => {
                handleSelectOption(option);
                inputRef.current.blur();
              }}
              dense={true}
              selected={index === highlightedIndex}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Chip label={option} size="small" />
                <Box>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOptionToEdit(option);
                      setEditValue(option);
                      setOpenEditDialog(true);
                      setIsDialogOpen(true);
                    }}
                  >
                    <MoreHorizIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </MenuItem>
          ))}
          {!options.map(opt => opt?.toLowerCase()).includes(inputValue.toLowerCase()) && inputValue && (
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                handleCreateOption(true);
                inputRef.current.blur();
              }}
              selected={highlightedIndex === filteredOptions.length}
            >
              <Typography variant="body1" sx={{ mr: 1 }}>Create</Typography>
              <Chip label={inputValue} size="small" />
            </MenuItem>
          )}
        </Box>
      )}
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        PaperProps={{
          sx: { 
            zIndex: 1400,
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{optionToDelete}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" color="secondary" onClick={() => setOpenConfirmDelete(false)}>Cancel</Button>
          <Button size="small" onClick={() => handleDeleteOption(optionToDelete)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Option Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
          setIsDialogOpen(false);
        }}
        PaperProps={{
          sx: { 
            zIndex: 1400,
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <DialogContent sx={{ width: theme.spacing(30) }}>
          <TextField
            autoFocus
            margin="dense"
            label="Option name"
            type="text"
            fullWidth
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" color="secondary" onClick={() => {
            setOpenEditDialog(false);
            setIsDialogOpen(false);
          }}>Cancel</Button>
          <Button size="small" color="error" onClick={() => {
            setOpenEditDialog(false);
            setOptionToDelete(optionToEdit);
            setOpenConfirmDelete(true);
            setIsDialogOpen(false);
          }}>
            Delete
          </Button>
          <Button size="small" sx={{minWidth: 'auto'}} onClick={handleEditOption} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Define prop types for better type checking
CustomSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  onEditOption: PropTypes.func,
  onDeleteOption: PropTypes.func,
};

export default React.memo(CustomSelect);