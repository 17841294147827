import React, { useContext } from 'react';
import { Breadcrumbs, Link, Typography, Box, IconButton, Button, Stack } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ThemeContext } from '../ThemeContext';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { supabase } from '../utils/SupabaseClient';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const theme = useTheme();
  const { mode, toggleTheme } = useContext(ThemeContext);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/auth');
  };

  return (
    <Box sx={{ my: 2, mx: 1.9, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.palette.background.default }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color={location.pathname === '/' ? 'textPrimary' : theme.palette.secondary.main}
          onClick={() => navigate('/')}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            fontWeight: location.pathname === '/' ? 'bold' : 'normal',
            '&:hover': { color: 'text.primary' }
          }}
        >
          Workouts
        </Link>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const displayValue = value.replace(/_/g, ' ');

          // Check if this is the last segment and it looks like a UUID
          const isSessionId = isLast && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(value);

          return isLast ? (
            <Typography
              color="textPrimary"
              key={to}
              sx={{ fontWeight: 'bold' }}
            >
              {isSessionId ? 'Session data' : displayValue.charAt(0).toUpperCase() + displayValue.slice(1)}
            </Typography>
          ) : (
            <Link
              key={to}
              color={theme.palette.secondary.main}
              onClick={() => navigate(to)}
              sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { color: 'text.primary' } }}
            >
              {displayValue.charAt(0).toUpperCase() + displayValue.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
      <Stack direction={'row'} gap={1}>
        <IconButton onClick={toggleTheme} color="inherit">
          {mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
        <Button variant="text" onClick={handleLogout}>
          Logout
        </Button>
      </Stack>
      
    </Box>
  );
};

export default Header;