import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import { supabase } from '../utils/SupabaseClient';

const SessionTypesContext = createContext();

export const SessionTypesProvider = ({ children, trackId }) => {
    const [sessionTypes, setSessionTypes] = useState([]);
    const [sessionTypesRef, setSessionTypesRef] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const sessionTypesSubscription = useRef(null);

    useEffect(() => {
        const fetchSessionTypes = async () => {
            setIsLoading(true);
            const { data, error } = await supabase
                .from('session_types')
                .select('id, name')
                .eq('track_id', trackId);

            if (error) {
                console.error('Error fetching session types:', error);
            } else {
                setSessionTypes(data.map(type => type.name));
                setSessionTypesRef(data);
            }
            setIsLoading(false);
        };

        fetchSessionTypes();

        const subscription = supabase
            .channel('session_types_changes')
            .on('postgres_changes', 
                { 
                    event: '*', 
                    schema: 'public', 
                    table: 'session_types',
                    filter: `track_id=eq.${trackId}`
                }, 
                (payload) => {
                    fetchSessionTypes();
                }
            )
            .subscribe();

        sessionTypesSubscription.current = subscription;

        return () => {
            supabase.removeChannel(subscription);
        };
    }, [trackId]);

    const handleCreateSessionType = useCallback(async (newType) => {
        const { data, error } = await supabase
            .from('session_types')
            .insert({ name: newType, track_id: trackId })
            .select()
            .single();

        if (error) {
            console.error('Error creating session type:', error);
            return null; // Return null in case of error
        } else {
            setSessionTypes(prev => [...prev, data.name]);
            setSessionTypesRef(prev => [...prev, data]);
            return data; // Return the created type
        }
    }, [trackId]);

    const handleEditSessionType = useCallback(async (oldType, newType) => {
        const typeToEdit = sessionTypesRef.find(type => type.name === oldType);
        if (typeToEdit) {
            const { error } = await supabase
                .from('session_types')
                .update({ name: newType })
                .eq('id', typeToEdit.id);

            if (error) {
                console.error('Error updating session type:', error);
            } else {
                setSessionTypes(prev => prev.map(opt => (opt === oldType ? newType : opt)));
                setSessionTypesRef(prev => prev.map(type => type.name === oldType ? { ...type, name: newType } : type));
            }
        }
    }, [sessionTypesRef]);

    const handleDeleteSessionType = useCallback(async (typeToDelete) => {
        const type = sessionTypesRef.find(type => type.name === typeToDelete);
        if (type) {
            const { error } = await supabase
                .from('session_types')
                .delete()
                .eq('id', type.id);

            if (error) {
                console.error('Error deleting session type:', error);
            } else {
                setSessionTypes(prev => prev.filter(opt => opt !== typeToDelete));
                setSessionTypesRef(prev => prev.filter(t => t.name !== typeToDelete));
            }
        }
    }, [sessionTypesRef]);

    return (
        <SessionTypesContext.Provider value={{
            sessionTypes,
            sessionTypesRef,
            isLoading,
            handleCreateSessionType,
            handleEditSessionType,
            handleDeleteSessionType
        }}>
            {children}
        </SessionTypesContext.Provider>
    );
};

export default SessionTypesContext;