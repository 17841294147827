import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from './components/Header';
import { useTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

function App() {
  const theme = useTheme();

  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ zIndex: 9999 }}>
      <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', pb: 10 }}>
        <Container>
          <Header />
          <Outlet />
        </Container>
      </Box>
    </SnackbarProvider>
  );
}

export default App;