import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeContextProvider } from './ThemeContext';
import WorkoutList from './pages/WorkoutList';
import WorkoutDetail from './pages/WorkoutDetail';
import Workouts from './pages/Workouts';
import './index.css';
import App from './App';
import { useTheme } from '@mui/material/styles';
import PreToJSON from './pages/PreToJSON';
import Tracks from './pages/Tracks';
import TrackDetails from './pages/TrackDetails';
import Auth from './pages/Auth';
import EditSessionPage from './pages/EditSessionPage';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound'; // Import NotFound component

const root = ReactDOM.createRoot(document.getElementById('root'));

const ThemedApp = () => {
  const theme = useTheme();

  React.useEffect(() => {
    document.body.style.setProperty('--background-color', theme.palette.background.default);
    document.body.style.setProperty('--text-color', theme.palette.text.primary);
  }, [theme]);

  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <PrivateRoute>
                <Workouts />
              </PrivateRoute>
            }
          />
          <Route
            path="list"
            element={
              <PrivateRoute>
                <WorkoutList />
              </PrivateRoute>
            }
          />
          <Route
            path="templates"
            element={
              <PrivateRoute>
                <div>Templates Page</div>
              </PrivateRoute>
            }
          />
          <Route
            path="insights_and_analytics"
            element={
              <PrivateRoute>
                <div>Insights and Analytics Page</div>
              </PrivateRoute>
            }
          />
          <Route
            path="list/:id"
            element={
              <PrivateRoute>
                <WorkoutDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="prototype"
            element={
              <PrivateRoute>
                <PreToJSON />
              </PrivateRoute>
            }
          />
          <Route
            path="tracks"
            element={
              <PrivateRoute>
                <Tracks />
              </PrivateRoute>
            }
          />
          <Route
            path="/tracks/:trackId"
            element={
              <PrivateRoute>
                <TrackDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/tracks/:trackId/:sessionId"
            element={
              <PrivateRoute>
                <EditSessionPage />
              </PrivateRoute>
            }
          />
          {/* Catch-All Route for 404 Page */}
          <Route
            path="*"
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <ThemedApp />
    </ThemeContextProvider>
  </React.StrictMode>
);