import React from 'react';
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Typography, Box, useMediaQuery, Card, CardContent, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import data from '../data/workouts';

const { workouts } = data;

const tableCellStyle = { borderBottom: 'none' };

const WorkoutList = () => {
  const sortedWorkouts = workouts.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const navigate = useNavigate();

  const groupedWorkouts = sortedWorkouts.reduce((acc, workout) => {
    const date = new Date(workout.dateTime).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
    if (!acc[date]) acc[date] = [];
    acc[date].push(workout);
    return acc;
  }, {});

  return (
    <Box>
      <Box display={isMobile ? "block" : "flex"} justifyContent={isMobile ? "initial" : "center"}>
        {isMobile ? (
          <Box>
            {Object.keys(groupedWorkouts).map(date => (
              <React.Fragment key={date}>
                <Typography style={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(4), color: theme.palette.text.primary }}>{date}</Typography>
                {groupedWorkouts[date].map(workout => (
                  <Card
                    key={workout.id}
                    sx={{
                      backgroundColor: 'transparent',
                      border: `1px solid ${theme.palette.primary.main}`,
                      boxShadow: 'none',
                      marginTop: theme.spacing(1),
                      backgroundImage: 'none'
                    }}
                    onClick={() => navigate(`/list/${workout.id}`)}
                  >
                    <CardContent style={{paddingBottom: theme.spacing(2)}}>
                      <Typography style={{ color: theme.palette.text.primary, marginBottom: theme.spacing(1) }}>{`${workout.type.charAt(0).toUpperCase() + workout.type.slice(1)} at ${new Date(workout.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</Typography>
                      <Typography style={{ color: theme.palette.text.secondary }}>Burned <Typography component="span" style={{ color: theme.palette.text.primary }}>{workout.calories} kcal</Typography> in <Typography component="span" style={{ color: theme.palette.text.primary }}>{workout.time} min</Typography></Typography>
                      <Typography style={{ color: theme.palette.text.secondary }}>Tiredness: <Typography component="span" style={{ color: theme.palette.text.primary }}>{workout.tiredness} </Typography></Typography>
                      <Typography style={{ color: theme.palette.text.secondary }}>Performance: <Typography component="span" style={{ color: theme.palette.text.primary }}>{workout.performance}</Typography></Typography>
                    </CardContent>
                  </Card>
                ))}
              </React.Fragment>
            ))}
          </Box>
        ) : (
          <TableContainer component={Box} style={{ width: 'auto', backgroundColor: 'transparent' }}>
            <Table stickyHeader style={{ width: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={tableCellStyle}>Time</TableCell>
                  <TableCell style={tableCellStyle}>Type</TableCell>
                  <TableCell style={tableCellStyle}>Duration(m)</TableCell>
                  <TableCell style={tableCellStyle}>Tiredness</TableCell>
                  <TableCell style={tableCellStyle}>Performance</TableCell>
                  <TableCell style={tableCellStyle}>Calories</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(groupedWorkouts).map(date => (
                  <React.Fragment key={date}>
                    <TableRow>
                      <TableCell colSpan={6} style={{borderBottom: 'none', padding: theme.spacing(0), paddingTop: theme.spacing(1)}}>
                        <Typography style={{ padding: '16px', color: theme.palette.text.primary }}>{date}</Typography>
                      </TableCell>
                    </TableRow>
                    {groupedWorkouts[date].map(workout => (
                      <TableRow
                        key={workout.id}
                        hover
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => navigate(`/list/${workout.id}`)}
                      >
                        <TableCell style={tableCellStyle}>{new Date(workout.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</TableCell>
                        <TableCell style={tableCellStyle}>{workout.type.charAt(0).toUpperCase() + workout.type.slice(1)}</TableCell>
                        <TableCell style={tableCellStyle}>{workout.time}</TableCell>
                        <TableCell style={tableCellStyle}>{workout.tiredness}</TableCell>
                        <TableCell style={tableCellStyle}>{workout.performance}</TableCell>
                        <TableCell style={tableCellStyle}>{workout.calories}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

export default WorkoutList;