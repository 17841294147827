import React, { useState, useContext, useCallback, memo } from 'react';
import { TextField, Button, Stack, Typography, CircularProgress, LinearProgress, Box } from '@mui/material';
import CustomSelect from '../components/CustomSelect';
import SessionForm from '../components/SessionForm';
import SessionTypesContext from '../contexts/sessionTypesContext';
import useTimer from '../hooks/useTimer';
import Confetti from 'react-confetti';
import { useTheme } from '@mui/material/styles';

const getCurrentDateTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() >= 30 ? 30 : 0, 0, 0);
    return now;
};

const TimerDisplay = memo(({ timer, handlePauseResume, goalDuration }) => {
    const theme = useTheme()
    const formatTime = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        const millis = Math.floor(milliseconds % 1000);
        return {
            minutes,
            seconds,
            millis
        };
    };

    const { minutes, seconds, millis } = formatTime(timer);
    const progress = goalDuration ? (timer / (goalDuration * 60000)) * 100 : 0;
    const cappedProgress = Math.min(progress, 100);

    return (
        <Button onClick={handlePauseResume} sx={{ px: 2 }}>
            <Stack direction={'column'} alignItems={'baseline'} sx={{ position: 'relative', top: '12px' }}>
                <Typography variant="body" color={'secondary'} sx={{ fontStyle: 'italic' }}>Session timer:</Typography>
                <Stack direction={'row'} alignItems={'baseline'} sx={{ fontStyle: 'italic', fontFamily: 'monospace', position: 'relative', top: '-12px' }}>
                    <Typography variant="h1" sx={{ fontWeight: 'bold' }}>{`${minutes.toString().padStart(2, '0')}'${seconds.toString().padStart(2, '0')}"`}</Typography>
                    <Typography variant="body">{millis.toString().padStart(3, '0')}</Typography>
                </Stack>
                {goalDuration > 0 && (<Typography variant="body" color={'secondary'} sx={{ fontStyle: 'italic' }}>Progress:</Typography>)}
                {goalDuration > 0 && (
                    <Box sx={{ width: '100%', position: 'relative' }}>
                        <LinearProgress
                            variant="determinate"
                            value={cappedProgress}
                            sx={{
                                height: 20,
                                borderRadius: 10,
                                '& .MuiLinearProgress-bar': {
                                    borderRadius: 10,
                                },
                            }}
                        />
                        <Typography
                            variant="body"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: `${cappedProgress}%`,
                                transform: progress > 100 ? 'translate(-110%, -50%)': 'translate(-125%, -50%)',
                                whiteSpace: 'nowrap',
                                color: theme.palette.background.default,
                            }}
                        >
                            {`${progress > 100 ? '🎉' : ''} ${Math.round(progress)}%`}
                        </Typography>
                        {progress >= 100 && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    pointerEvents: 'none',
                                    zIndex: 9999
                                }}
                            >
                                <Confetti width={window.innerWidth} height={window.innerHeight} />
                            </Box>
                        )}
                    </Box>
                )}
            </Stack>
        </Button>
    );
});

function StartSession({ trackId, handleClose }) {
    const { 
        sessionTypes, 
        sessionTypesRef, 
        isLoading, 
        handleCreateSessionType, 
        handleEditSessionType, 
        handleDeleteSessionType 
    } = useContext(SessionTypesContext);

    const { timer, isRunning, handlePauseResume } = useTimer();

    // Form state
    const [formData, setFormData] = useState({
        date: getCurrentDateTime(),
        endTime: '',
        duration: '',
        focus: '',
        performance: '',
        fun: '',
        retro: '',
        type: '',
        session_type_id: '',
    });
    const [step, setStep] = useState(1);
    const [previousIsRunning, setPreviousIsRunning] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleStepChange = (newStep) => {
        if (newStep === 2) {
            setPreviousIsRunning(isRunning);
            if(isRunning) handlePauseResume();
            setFormData(prev => ({
                ...prev,
                duration: Math.max(1, Math.ceil(timer / 60000))
            }));
        } else if (newStep === 1) {
            if (previousIsRunning) {
                handlePauseResume();}
        }
        setStep(newStep);
    };

    const handleSessionFormClose = () => {
        handleClose();
    };

    // Updated handleCreateSessionType to handle returned type
    const handleSessionTypeChange = useCallback(async (newValue) => {
        if (newValue === null) {
            setFormData(prev => ({
                ...prev,
                session_type_id: '',
                type: ''
            }));
        } else {
            const selectedType = sessionTypesRef.find(type => type.name === newValue);
            if (selectedType) {
                setFormData(prev => ({
                    ...prev,
                    session_type_id: selectedType.id,
                    type: selectedType.name
                }));
            }
        }
    }, [sessionTypesRef]);

    // Updated onCreateOption to handle returned type
    const handleCreateSessionTypeWrapper = async (createdOption) => {
        const createdType = await handleCreateSessionType(createdOption);
        if (createdType) {
            setFormData(prev => ({
                ...prev,
                type: createdType.name,
                session_type_id: createdType.id
            }));
        }
    };

    // Memoize the handler to prevent it from being recreated on every render
    const handleSessionChange = useCallback((updatedSession) => {
        setFormData(prev => ({
            ...prev,
            ...updatedSession
        }));
    }, []);

    return (
        <>
            {step === 1 && (
                    <Stack gap={2}>
                        <Stack direction={'column'} alignItems={'center'}>
                            <TimerDisplay timer={timer} handlePauseResume={handlePauseResume} goalDuration={formData.duration} />
                        </Stack>
                        <TextField
                            label="Goal session time (m)"
                            type="number"
                            name="duration"
                            value={formData.duration}
                            onChange={handleChange}
                            fullWidth
                        />
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <CustomSelect
                                label="Session Type"
                                options={sessionTypes}
                                selectedOption={formData.type || null}
                                onChange={handleSessionTypeChange}
                                onCreateOption={handleCreateSessionTypeWrapper}
                                onEditOption={(oldType, newType) => {
                                    handleEditSessionType(oldType, newType)
                                    if(formData.type === oldType) {
                                        setFormData(prev => ({
                                            ...prev,
                                            type: newType,
                                            session_type_id: sessionTypesRef.find(t => t.name === newType)?.id || prev.session_type_id
                                        }));
                                    }
                                }}
                                onDeleteOption={handleDeleteSessionType}
                            />
                        )}
                        <TextField
                            label="Focus"
                            name="focus"
                            value={formData.focus}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            rows={4}
                        />
                        <Button onClick={() => handleStepChange(2)} variant="contained" color="primary">
                            Finish Session
                        </Button>
                    </Stack>
            )}
            {step === 2 && (
                <>
                    <Button sx={{width: '100%'}}onClick={() => handleStepChange(1)}>←</Button>
                    <SessionForm
                        trackId={trackId}
                        session={formData}
                        handleClose={handleSessionFormClose}
                        onSessionChange={handleSessionChange} // Pass the memoized handler
                    />
                </>
            )}
        </>
    );
}

export default StartSession;