import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Dialog, DialogTitle, DialogContent, useMediaQuery, SwipeableDrawer, Box, Typography, CircularProgress } from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ActivityIndex from '../components/ActivityIndex';
import styled from 'styled-components';
import AddTrack from '../components/AddTrack';
import { useTheme } from '@emotion/react';
import { supabase } from '../utils/SupabaseClient';
import SessionForm from '../components/SessionForm'; // Import SessionForm
import StartSession from '../components/StartSession'; // Import StartSession
import { SessionTypesProvider } from '../contexts/sessionTypesContext';

const StyledListItem = styled(ListItem)`
  &:hover .icon-button {
    visibility: visible;
  }
`;

const StyledIconButton = styled(IconButton)`
  visibility: hidden;
`;

const Tracks = () => {
    const theme = useTheme();
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openAddSessionDialog, setOpenAddSessionDialog] = useState(false); // State for Add Session Dialog
    const [openStartSessionDialog, setOpenStartSessionDialog] = useState(false); // State for Start Session Dialog
    const [selectedTrackId, setSelectedTrackId] = useState(null); // State to keep track of the selected track
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchTracks = async () => {
            const { data, error } = await supabase
                .from('tracks')
                .select('*');
            if (error) {
                console.error('Error fetching tracks:', error);
            } else {
                setGoals(data);
            }
            setLoading(false);
        };

        fetchTracks();

        const subscription = supabase
            .channel('public:tracks')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'tracks' }, payload => {
                // Update the state based on the change type instead of refetching all tracks
                if (payload.eventType === 'INSERT') {
                    setGoals(prev => [...prev, payload.new]);
                } else if (payload.eventType === 'UPDATE') {
                    setGoals(prev => prev.map(track => track.id === payload.new.id ? payload.new : track));
                } else if (payload.eventType === 'DELETE') {
                    setGoals(prev => prev.filter(track => track.id !== payload.old.id));
                }
            })
            .subscribe();

        return () => {
            supabase.removeChannel(subscription);
        };
    }, []);

    const stopProp = (e) =>{ 
        e.stopPropagation();
    }

    const handleAddTrackClick = () => {
        if (isMobile) {
            setOpenDrawer(true);
        } else {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOpenDrawer(false);
        setOpenAddSessionDialog(false);
        setOpenStartSessionDialog(false);
    };

    // Handlers for Add Session
    const handleAddSessionClick = (trackId) => {
        setSelectedTrackId(trackId);
        setOpenAddSessionDialog(true);
    };

    // Handlers for Start Session
    const handleStartSessionClick = (trackId) => {
        setSelectedTrackId(trackId);
        setOpenStartSessionDialog(true);
    };

    return (
        <Stack>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <List>
                    {goals.map(el => (
                        <StyledListItem
                            key={el.id}
                            button
                            onClick={() => navigate(`/tracks/${el.name.replace(/ /g, '_')}`)}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: 'transparent' }}>
                                    <ActivityIndex 
                                        size={40}
                                        color={"red"} 
                                        ringBgColor={"red"} 
                                        showText={false}
                                        progress={120}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={el.name.replace(/^\w/, c => c.toUpperCase())}/>
                            <StyledIconButton 
                                onClick={(e) => { stopProp(e); handleStartSessionClick(el.id); }} 
                                className="icon-button" 
                                title="Start session"
                            >
                                <PlayCircleFilledWhiteOutlinedIcon/>
                            </StyledIconButton>
                            <StyledIconButton 
                                onClick={(e) => { stopProp(e); handleAddSessionClick(el.id); }} 
                                className="icon-button" 
                                title="Add session"
                            >
                                <AddCircleOutlineOutlinedIcon/>
                            </StyledIconButton>
                        </StyledListItem>
                    ))}
                </List>
            )}
            <Box sx={{ height: 80 }} />
            <Button 
                variant='contained' 
                onClick={handleAddTrackClick}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        background: theme.palette.primary.dark,
                    },
                }}
                startIcon={<AddIcon />}
            >
                Add track
            </Button>
            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    Your new track
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <AddTrack handleClose={handleClose} />
                </DialogContent>
            </Dialog>
            <SwipeableDrawer
                anchor="bottom"
                open={openDrawer}
                onClose={handleClose}
                onOpen={() => setOpenDrawer(true)}
                sx={{
                    '& .MuiPaper-root': {
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        padding: 3,
                        paddingTop: 4
                    },
                }}
            >
                <Box
                    sx={{
                        width: 30,
                        height: 6,
                        backgroundColor: 'grey.300',
                        borderRadius: 3,
                        position: 'absolute',
                        top: 8,
                        left: 'calc(50% - 15px)',
                    }}
                />
                <Typography variant="h5" style={{fontWeight: 'bold'}}>New track</Typography>
                <Box sx={{marginTop: 1}}>
                    <AddTrack handleClose={handleClose} />
                </Box>
            </SwipeableDrawer>

            {/* Add Session Dialog */}
            <Dialog 
                open={openAddSessionDialog} 
                onClose={handleClose} 
                fullWidth 
                maxWidth="sm"
            >
                <DialogTitle>
                    Add New Session
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SessionTypesProvider trackId={selectedTrackId}>
                        <SessionForm trackId={selectedTrackId} handleClose={handleClose} />
                    </SessionTypesProvider>
                </DialogContent>
            </Dialog>

            {/* Start Session Dialog */}
            <Dialog 
                open={openStartSessionDialog} 
                onClose={handleClose} 
                fullWidth 
                maxWidth="sm"
            >
                <DialogTitle>
                    Start New Session
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SessionTypesProvider trackId={selectedTrackId}>
                        <StartSession trackId={selectedTrackId} handleClose={handleClose} />
                    </SessionTypesProvider>
                </DialogContent>
            </Dialog>
        </Stack>
    );
};

export default Tracks;