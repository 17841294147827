import React, { useState, useEffect } from 'react';
import { TextField, Button, Stack, Typography, Select, MenuItem, InputLabel, FormControl, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/SupabaseClient';
import { useForm } from 'react-hook-form';

function AddTrack({ handleClose }) {
    const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        defaultValues: {
            trackName: '',
            trackDescription: '',
            showUpGoal: '',
            timeUnit: 'minutes',
            goal_type: 'daily'
        }
    });
    const [userId, setUserId] = useState(null);
    
    const [submitError, setSubmitError] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            setUserId(user?.id);
        };

        fetchUser();
    }, []);

    const onSubmit = async (data) => {
        // Clear previous errors
        clearErrors();
        setSubmitError('');

        // Validate form
        let newErrors = {};
        if (!data.trackName.trim()) {
            newErrors.trackName = "Track can't be created without a name";
        }
        if (!data.showUpGoal || parseFloat(data.showUpGoal) <= 0) {
            newErrors.showUpGoal = "Please enter a valid number";
        }

        // If there are errors, set them and stop submission
        if (Object.keys(newErrors).length > 0) {
            Object.entries(newErrors).forEach(([field, message]) => setError(field, { type: 'manual', message }));
            return;
        }

        // Submission Logic
        const { error } = await supabase
            .from('tracks')
            .insert([
                {
                    name: data.trackName.toLowerCase(),
                    description: data.trackDescription,
                    goal: data.timeUnit === 'minutes' ? data.showUpGoal : data.showUpGoal * 60,
                    goal_type: data.goal_type,
                    user_id: userId
                }
            ]);

        if (error) {
            setSubmitError(error.message);
        } else {
            handleClose();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2} sx={{ marginTop: 1 }}>
                {submitError && (
                    <Alert severity="error">{submitError}</Alert>
                )}
                <TextField
                    label="Name"
                    {...register('trackName', {
                        validate: value => {
                            const reservedChars = /[&+,/:;=?@#|'<>.^*()%!]/;
                            if (reservedChars.test(value)) {
                                return 'Track name cannot contain reserved URL characters';
                            }
                            return true;
                        }
                    })}
                    fullWidth
                    error={!!errors.trackName}
                    helperText={errors.trackName?.message}
                />
                <TextField
                    label="Description"
                    {...register('trackDescription')}
                    fullWidth
                    multiline
                    rows={4}
                />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Show up goal</Typography>
                <Stack direction={'row'} spacing={2}>
                    <TextField
                        label="Time"
                        type="number"
                        {...register('showUpGoal', { valueAsNumber: true })}
                        sx={{ flex: 1 }}
                        error={!!errors.showUpGoal}
                        helperText={errors.showUpGoal?.message}
                        inputProps={{ min: 1 }}
                    />
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>Unit</InputLabel>
                        <Select
                            label="Unit"
                            {...register('timeUnit')}
                        >
                            <MenuItem value="minutes">Minutes</MenuItem>
                            <MenuItem value="hours">Hours</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>When</InputLabel>
                        <Select
                            label="When"
                            {...register('goal_type')}
                        >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="every 7 days">Every 7 days</MenuItem>
                            <MenuItem value="every 30 days">Every 30 days</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Typography variant="body2">
                    Set how much time you want to spend on this activity. This will be your 100% personal activity index (PAI). PAI shows how consistent you are. You can read how and why PAI works <Link to="/manifest" target="_blank">here</Link>.
                </Typography>
                <Button type="submit" variant="contained" color="primary">
                    Create
                </Button>
            </Stack>
        </form>
    );
}

export default AddTrack;