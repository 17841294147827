import { useState, useEffect, useRef, useCallback } from 'react';

const useTimer = (initialTime = 0) => {
    const [timer, setTimer] = useState(initialTime);
    const [isRunning, setIsRunning] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        let startTime;
        const updateTimer = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime + elapsedTime;
            setTimer(elapsed);
            if (isRunning) {
                timerRef.current = requestAnimationFrame(updateTimer);
            }
        };

        if (isRunning) {
            timerRef.current = requestAnimationFrame(updateTimer);
        } else {
            cancelAnimationFrame(timerRef.current);
        }

        return () => cancelAnimationFrame(timerRef.current);
    }, [isRunning, elapsedTime]);

    const handlePauseResume = useCallback(() => {
        if (isRunning) {
            setElapsedTime(timer);
        }
        setIsRunning(!isRunning);
    }, [isRunning, timer]);

    return { timer, isRunning, handlePauseResume };
};

export default useTimer;