import React, { createContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState('dark');

  const theme = useMemo(() => createTheme({
    palette: {
      mode,
      primary: {
        main: mode === 'light' ? '#000000' : '#ffffff', // Black for light mode, white for dark mode
      },
      secondary: {
        main: '#808080', // Gray
      },
      background: {
        default: mode === 'light' ? '#ffffff' : '#121212', // White for light mode, dark for dark mode
        paper: mode === 'light' ? '#f5f5f5' : '#1e1e1e', // Light gray for light mode, dark gray for dark mode
      },
      text: {
        primary: mode === 'light' ? '#000000' : '#ffffff', // Black for light mode, white for dark mode
        secondary: '#808080', // Gray
      },
      
    },
  }), [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };