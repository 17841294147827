import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../utils/SupabaseClient';
import { TextField, Button, Stack, Typography } from '@mui/material';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const handleAuth = async () => {
    setError('');
    try {
      if (isSignUp) {
        const { error } = await supabase.auth.signUp({ email, password });
        if (error) throw error;
        console.log('Sign up successful!');
      } else {
        const { error } = await supabase.auth.signInWithPassword({ email, password });
        if (error) throw error;
        console.log('Login successful!');
        navigate(from, { replace: true });
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: 400, margin: 'auto', mt: 8 }}>
      <Typography variant="h4">{isSignUp ? 'Sign Up' : 'Log In'}</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />
      <Button variant="contained" color="primary" onClick={handleAuth}>
        {isSignUp ? 'Sign Up' : 'Log In'}
      </Button>
      <Button onClick={() => setIsSignUp(!isSignUp)}>
        {isSignUp ? 'Already have an account? Log In' : "Don't have an account? Sign Up"}
      </Button>
    </Stack>
  );
};

export default Auth;