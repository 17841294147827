import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, IconButton, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@emotion/react';
import { supabase } from '../utils/SupabaseClient';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const tableCellStyle = { borderBottom: 'none' };
const columnLabels = { pb: 0.5, borderBottom: 'none' };

const SessionTable = React.memo(({ sessions, trackId, onDelete }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleClick = (session) => {
    navigate(`/tracks/${trackId}/${session.id}`);
  };

  const handleDelete = async (sessionId) => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        const { error } = await supabase
          .from('track_sessions')
          .delete()
          .eq('id', sessionId);

        if (error) throw error;

        onDelete(sessionId);
      } catch (error) {
        console.error('Error deleting session:', error);
      }
    }
  };

  const handleMenuClick = (event, session) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedSession(session);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSession(null);
  };

  const handleEdit = () => {
    if (selectedSession) {
      handleClick(selectedSession);
    }
    handleMenuClose();
  };

  const handleDeleteMenu = async () => {
    if (selectedSession) {
      await handleDelete(selectedSession.id);
    }
    handleMenuClose();
  };

  const groupedSessions = sessions
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .reduce((acc, session) => {
      if (!session.date) {
        console.error(`Missing dateTime for session: ${JSON.stringify(session)}`);
        return acc;
      }
      
      const date = new Date(session.date);
      if (isNaN(date)) {
        console.error(`Invalid date format: ${session.date}`);
        return acc;
      }
      
      const formattedDate = date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
      if (!acc[formattedDate]) acc[formattedDate] = [];
      acc[formattedDate].push(session);
      return acc;
    }, {});

  if (sessions.length === 0) {
    return null;
  }

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleDeleteMenu} sx={{ color: 'error.main' }}>Delete</MenuItem>
    </Menu>
  );

  const renderSessionCard = (session, index, date) => (
    <Card
      key={`session-${date}-${index}`}
      sx={{
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
        marginTop: theme.spacing(1),
        backgroundImage: 'none',
        position: 'relative'
      }}
      onClick={() => handleClick(session)}
    >
      <CardContent sx={{ paddingBottom: theme.spacing(2) }}>
        <Typography sx={{ color: theme.palette.text.primary, marginBottom: theme.spacing(1) }}>
          {`Session at ${new Date(session.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Duration: <Typography component="span" sx={{ color: theme.palette.text.primary }}>{session.duration} min</Typography>
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Performance: <Typography component="span" sx={{ color: theme.palette.text.primary }}>{session.performance}</Typography>
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Has Focus: <Typography component="span" sx={{ color: theme.palette.text.primary }}>{session.focus ? 'Yes' : 'No'}</Typography>
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Has Retro: <Typography component="span" sx={{ color: theme.palette.text.primary }}>{session.retro ? 'Yes' : 'No'}</Typography>
        </Typography>
        <IconButton
          onClick={(e) => handleMenuClick(e, session)}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </CardContent>
    </Card>
  );

  const renderSessionRow = (session, index, date) => (
    <TableRow
      key={`session-${date}-${session.id || index}`}
      hover
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      onClick={() => handleClick(session)}
    >
      <TableCell sx={tableCellStyle}>{new Date(session.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</TableCell>
      <TableCell sx={tableCellStyle}>{session.duration}</TableCell>
      <TableCell sx={tableCellStyle}>{session.performance}</TableCell>
      <TableCell sx={tableCellStyle}>{session.focus ? 'Yes' : 'No'}</TableCell>
      <TableCell sx={tableCellStyle}>{session.retro ? 'Yes' : 'No'}</TableCell>
      <TableCell sx={tableCellStyle}>
        <IconButton
          onClick={(e) => handleMenuClick(e, session)}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  if (isMobile) {
    return (
      <Box>
        {Object.keys(groupedSessions).map(date => (
          <React.Fragment key={date}>
            <Typography sx={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2), color: theme.palette.text.primary }}>{date}</Typography>
            {groupedSessions[date].map((session, index) => renderSessionCard(session, index, date))}
          </React.Fragment>
        ))}
        {renderMenu()}
      </Box>
    );
  }

  return (
    <TableContainer component={Box} sx={{ width: 'auto', backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
      <Table stickyHeader sx={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={columnLabels}>DateTime</TableCell>
            <TableCell sx={columnLabels}>Duration(m)</TableCell>
            <TableCell sx={columnLabels}>Performance</TableCell>
            <TableCell sx={columnLabels}>Has Focus</TableCell>
            <TableCell sx={columnLabels}>Has Retro</TableCell>
            <TableCell sx={columnLabels}><></></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedSessions).map(date => (
            <React.Fragment key={date}>
              <TableRow key={`date-${date}`}>
                <TableCell colSpan={6} sx={{ borderBottom: 'none', padding: theme.spacing(0), paddingTop: theme.spacing(1) }}>
                  <Typography sx={{ padding: '16px', color: theme.palette.text.primary }}>{date}</Typography>
                </TableCell>
              </TableRow>
              {groupedSessions[date].map((session, index) => renderSessionRow(session, index, date))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      {renderMenu()}
    </TableContainer>
  );
});

export default SessionTable;