import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { v4 as uuidv4 } from 'uuid';

// Utility function to adjust color
const adjustColor = (inputColor, hueShift = 36, lightnessShift = 0.1, progressFactor = 1) => {
  let color = chroma(inputColor);
  let [h, s, l] = color.hsl();
  h = (h + hueShift * progressFactor) % 360;
  l = Math.min(1, Math.max(0, l + lightnessShift * progressFactor));
  return chroma.hsl(h, s, l).hex();
};

// Styled components
const CircleBackground = styled.div.attrs(({ $ringBgColor, $strokeWidth, theme, $hasCustomColor }) => ({
  style: {
    backgroundColor: $ringBgColor,
    maskImage: `radial-gradient(farthest-side, transparent calc(100% - ${$strokeWidth}px), black calc(100% - ${$strokeWidth}px))`,
    opacity: $hasCustomColor ? (theme?.palette?.mode === 'dark' ? 0.25 : 0.15) : 0.075,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const ProgressText = styled.div.attrs(({ size, $textColor, $textSize }) => ({
  style: {
    fontSize: `${$textSize || size / 5}px`,
    color: $textColor,
  },
}))`
  position: absolute;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RoundCap = styled.div.attrs(({ $strokeWidth, $color, $position, $full, $progress, $angle, $size, $hueShift, $lightnessShift }) => {
  const progressFactor = Math.min($progress / 100, 1);
  const gradientStart = adjustColor($color, $hueShift * 0.95, $lightnessShift * 0.95, progressFactor);
  const gradientEnd = adjustColor($color, $hueShift, $lightnessShift, progressFactor);

  return {
    style: {
      width: `${$strokeWidth}px`,
      height: `${$strokeWidth}px`,
      background: $position === 'end'
        ? $full
          ? `linear-gradient(${gradientStart}, ${gradientEnd})`
          : `linear-gradient(transparent, ${gradientEnd} 50%)`
        : `linear-gradient(${$color} 50%, transparent)`,
      transform: `translate(-50%, -50%) rotate(${$angle}deg) translateX(calc(${$size / 2}px - ${$strokeWidth / 2}px))`,
    },
  };
})`
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
`;

const RingShadow = styled.div.attrs(({ $angle, $shadow, $shadowLength, $strokeWidth }) => ({
  style: {
    background: `conic-gradient(from ${$angle}deg, rgba(0, 0, 0, ${$shadow}) 0%, rgba(0, 0, 0, 0) ${$shadowLength}%)`,
    maskImage: `radial-gradient(farthest-side, transparent calc(100% - ${$strokeWidth}px), black calc(100% - ${$strokeWidth}px))`,
    transform: `rotate(${$angle}deg)`,
  },
}))`
  stroke-linecap: round;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const SvgContainer = styled.div.attrs(({ $rotation }) => ({
  style: {
    transform: `rotate(${$rotation}deg)`,
  },
}))`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle.attrs(({ r, $strokeWidth, $progress }) => ({
  style: {
    strokeDasharray: `${2 * Math.PI * r}`,
    strokeDashoffset: `${2 * Math.PI * r * (1 - $progress / 100)}`,
    strokeWidth:`${$strokeWidth}`
  },
}))`
  stroke: white;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
`;

const Bg = styled.div`
  background: ${({ $startColor, $endColor }) => `conic-gradient(${$startColor}, ${$endColor})`};
  width: 100%;
  height: 100%;
`;

const Container = styled.div.attrs(({ $size, $hoverShadowColor, $onClick }) => ({
  style: {
    width: `${$size}px`,
    height: `${$size}px`,
    cursor: $onClick ? 'pointer' : 'default',
  },
}))`
  position: relative;
  border-radius: 100%;
  transition: box-shadow 0.5s ease, top 0.5s ease;
  top: 0;
  ${({ $hoverShadowColor, $onClick }) => $onClick && `
    &:hover {
      box-shadow: 0 4px 12px rgba(${chroma($hoverShadowColor).rgb().join(', ')}, 0.5);
      top: -2px;
      cursor: pointer;
    }
  `}
`;

// Main component
const ActivityIndex = ({
  size = 100,
  strokeWidth = 40,
  progress = 0,
  color = "red",

  ringOverlapShadowOpacity = 0.25,
  ringOverlapShadowLength = 5,
  
  showText = true,
  textColor,
  textSize,
  ringBgColor,
  animationDuration = 500,
  hueShift = 32,
  lightnessShift = 0.15,
  actionOnClick,
}) => {
  const theme = useTheme();
  const [animatedProgress, setAnimatedProgress] = useState(0);
  const animationFrameId = useRef(null);
  const startTimeRef = useRef(null);
  
  // Add this line to create a unique ID for each instance
  const uniqueMaskId = useRef(`mask-${uuidv4()}`);

  // Animation function
  const animate = useCallback(() => {
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }

    const startProgress = animatedProgress;
    const endProgress = progress;
    startTimeRef.current = performance.now();

    const easeOut = (t) => t * (2 - t);

    const step = (currentTime) => {
      const elapsedTime = currentTime - startTimeRef.current;
      const progressTime = Math.min(elapsedTime / animationDuration, 1);
      const easedProgress = easeOut(progressTime);
      const currentProgress = startProgress + (endProgress - startProgress) * easedProgress;
      
      // Constrain the animated progress between start and end values
      const constrainedProgress = startProgress < endProgress
        ? Math.min(Math.max(startProgress, currentProgress), endProgress)
        : Math.max(Math.min(startProgress, currentProgress), endProgress);
      
      setAnimatedProgress(constrainedProgress);

      if (progressTime < 1) {
        animationFrameId.current = requestAnimationFrame(step);
      }
    };

    animationFrameId.current = requestAnimationFrame(step);
  }, [animatedProgress, progress, animationDuration]);

  // Trigger animation when progress changes
  useEffect(() => {
    if (progress !== animatedProgress) {
      animate();
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [animate, progress, animatedProgress]);

  // Calculate dimensions and angles
  const maxStrokeWidth = size * 0.2;
  const cappedStrokeWidth = Math.min(strokeWidth, maxStrokeWidth);
  const angle = animatedProgress > 100 ? 0 : (animatedProgress / 100) * 360;
  const radius = (size / 2) - (cappedStrokeWidth / 2);
  const cappedShadowLength = Math.min(ringOverlapShadowLength, 7);

  // Calculate colors
  const startColor = color;
  const endColor = adjustColor(startColor, hueShift, lightnessShift);

  const hasCustomRingBgColor = ringBgColor !== undefined;
  const finalRingBgColor = ringBgColor || color || theme.palette.primary.main;

  return (
    <Container 
      $size={size}
      $hoverShadowColor={color}
      $onClick={actionOnClick} // Attach actionOnClick to the component
      onClick={actionOnClick}
    >
      <CircleBackground 
        $strokeWidth={cappedStrokeWidth} 
        $ringBgColor={finalRingBgColor}
        theme={theme}
        $hasCustomColor={hasCustomRingBgColor}
      />
      <SvgContainer $rotation={animatedProgress > 100 ? (animatedProgress - 100) / 100 * 360 : 0}>
        <Svg viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg" style={{zIndex: 0}}>
          <defs>
            {/* Use the unique mask ID here */}
            <mask id={uniqueMaskId.current}>
              <Circle 
                cx={size / 2} 
                cy={size / 2}
                r={radius}
                $strokeWidth={cappedStrokeWidth}
                fill='none' 
                $progress={animatedProgress > 100 ? 100 : animatedProgress} 
              />
            </mask>
          </defs>
          {/* Use the unique mask ID here as well */}
          <foreignObject x="0" y="0" width={size} height={size} mask={`url(#${uniqueMaskId.current})`}>
            <Bg 
              $progress={animatedProgress} 
              $startColor={startColor} 
              $endColor={endColor}
            />
          </foreignObject>
        </Svg>
        <RoundCap
          $strokeWidth={cappedStrokeWidth}
          $size={size}
          $color={startColor}
          $angle={-90}
          $full
          // avoid denting out under the top roundcap
          style={{ zIndex: animatedProgress > 99 ? -1 : 0 }}
        />
        <RoundCap
          $strokeWidth={cappedStrokeWidth}
          $size={size}
          $color={startColor}
          $angle={angle - 90}
          $position='end'
          $full={animatedProgress > 95}
          style={{ zIndex: 2 }}
          $progress={animatedProgress}
          $hueShift={hueShift}
          $lightnessShift={lightnessShift}
        />
        <RingShadow
          $strokeWidth={cappedStrokeWidth}
          $angle={animatedProgress > 100 ? 0 : animatedProgress * 1.8}
          theme={theme}
          style={{zIndex: animatedProgress > 50 ? 1 : -1}}
          $shadow={ringOverlapShadowOpacity}
          $shadowLength={cappedShadowLength}
          $progress={animatedProgress}
          $color={endColor}
        />
        
      </SvgContainer>

      {/* Progress Text */}
      {showText && (
        <ProgressText 
          size={size/1.5} 
          $textColor={textColor || theme.palette.text.primary}
          $textSize={textSize}
        >
          {`${Math.round(progress)}%`}
        </ProgressText>
      )}
    </Container>
  );
};

export default ActivityIndex;


